import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import config from '../../config'
import * as routes from '../../routes'
import { media, zIndexes, dashedLine } from '../../styles'
import { colours, fonts, dimensions } from '../../theme'

import WithPrint from '../../hocs/WithPrint'
import WithThemes from '../../hocs/WithThemes'
import WithSession from '../../hocs/WithSession'
import WithActivities from '../../hocs/WithActivities'

import HomeStrava from '../../components/HomeStrava'
import Emoji from '../../components/Emoji'
import { SmallText, Text } from '../../components/Text'
import SiteHeader from '../../containers/SiteHeader'
import SiteContainer from '../../components/SiteContainer'
import { Link, LinkAsPrimaryButton } from '../../components/Link'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { ThemeCardTitle, ThemeCard } from '../../components/ThemeCard'
import { Title, FeaturedTitle, SectionTitle } from '../../components/Title'
import {
  ThemesListItem,
  ThemesListItemInner,
} from '../../components/ThemesList'
import {
  FeatureBackground,
  ElevationProfileSvg,
  TopoBackground,
} from '../../components/Background'
import { Figure } from '../../components/Figure'
import { PoweredByStravaAlt } from '../../components/Logo'
import { Icon } from '../../components/Icon'
import { PlainButton } from '../../components/Button'

import carousel01 from '../../images/carousel-01.jpg'
import carousel02 from '../../images/carousel-02.jpg'
import carousel03 from '../../images/carousel-03.jpg'
import carousel04 from '../../images/carousel-04.jpg'
import promo02 from '../../images/gift-voucher.png'
import topo01 from '../../images/topo-orange.svg'
import adamAndJake from '../../images/adam-and-jake-01.jpg'

import partnerGG from '../../images/partner-gg.svg'
import partnerRR from '../../images/partner-rhino-run.png'
import partnerPC from '../../images/partner-panceltic.png'
import partnerTribe from '../../images/partner-tribe.png'
import partner13Peaks from '../../images/partner-13peaks.png'
import partnerGbDivide from '../../images/partner-gbdivide.png'
import partnerBorderland500 from '../../images/partner-borderland500.png'
import partnerSolstice from '../../images/partner-solstice.svg'
import { mediaSizes } from '../../utils/sizes'

const NewFeatureFlag = styled.span`
  font-family: ${fonts.family02};
  font-size: ${fonts.size09};
  color: ${colours.primary};
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  line-height: 1;
`

const HeroSection = styled.section``
const HeroSiteContainer = styled(SiteContainer)``
const PromoSection = styled.section`
  margin-top: 6rem;
  margin-bottom: 6rem;
`
const PromosSiteContainer = styled(SiteContainer)`
  max-width: calc(122.6rem + 6rem + 6rem);
`

const DashedLine = styled.span`
  display: block;
  width: 3px;
  height: 30rem;
  margin: 6rem auto 7rem;
  ${dashedLine}
`

const ToTheTopButton = styled(PlainButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14rem;
  height: 14rem;
  font-size: ${fonts.size09};
  font-weight: ${fonts.weightBold};
  font-family: ${fonts.family02};
  text-transform: uppercase;
  text-align: center;
  padding: 2em;
  line-height: 1.2;
  background-color: ${colours.primary};
  color: ${colours.white};
  border-radius: 50%;
  border: 0.2rem solid ${colours.primary};

  &:focus,
  &:hover {
    color: ${colours.primary} !important;
  }
`

const ToTheTop = styled.div`
  margin: auto;

  ${ToTheTopButton} {
    margin: 0.4rem auto 0;
  }

  ${DashedLine} {
    height: 20rem;
    margin: 6rem auto 0;
  }
`

const IconGridInner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 15rem max-content auto;
  padding: 6rem 3rem 9rem;
  text-align: center;
  border-radius: ${dimensions.borderRadius};
  background-color: ${colours.grey11};
`

const IconGrid = styled(PromoSection)`
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 35rem));
  grid-gap: 6rem;
  justify-content: space-between;
  margin-top: 6rem;
  margin-bottom: 15rem;

  ${Title} {
    color: ${colours.secondary};
    font-size: ${fonts.size02};
    font-weight: ${fonts.weightMedium};
    margin-bottom: 0.4em;
  }
  ${Text} {
    color: ${colours.grey10};
    font-size: ${fonts.size02};
    line-height: 1.4;
  }

  .isvg {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5.5rem;
    align-self: flex-end;
  }

  .icon-freeShipping {
    width: 16.7rem;
    transform: translateX(-1rem);
  }

  .icon-printedLocally {
    width: 10.9rem;
  }

  .icon-sustainable {
    width: 10.4rem;
    transform: translateX(-1.2rem);
  }
`

const CtaCreateLink = styled(LinkAsPrimaryButton)`
  display: inline-flex;
  width: auto;
  padding-left: 6rem;
  padding-right: 6rem;
  line-height: 1.2;
`

const PrintGridA = styled(PromoSection)`
  display: grid;
  grid-template-columns: 4.66fr 7fr;
  grid-gap: 6rem;

  ${Title} {
    margin-top: 8rem;
    margin-bottom: 0.3em;
    font-weight: ${fonts.weightMedium};
    display: flex;
    align-items: center;
    column-gap: 3rem;
  }

  ${NewFeatureFlag} {
  }

  ${CtaCreateLink} {
    margin-top: 3rem;
  }

  ${Figure} {
    border-radius: ${dimensions.borederRadius};
    overflow: hidden;
  }

  ${PoweredByStravaAlt} {
    max-width: 18rem;
    margin-bottom: 7rem;
    margin-left: 17rem;
    margin-top: 17rem;
  }
`

const PrintGridB = styled(PrintGridA)`
  grid-template-columns: 7fr 4.1fr;
  grid-gap: 11.6rem;

  ${Title} {
    margin-top: 5rem;
  }

  .print-04 {
    max-width: 32.4rem;
    margin-top: 6rem;
  }
`

const PromoBoxInner = styled.div`
  display: flex;
  padding: 8rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const PromoBoxImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:before {
    background: no-repeat url(${topo01});
    background-size: cover;
    background-position: center center;
    position: absolute;
    z-index: 0;
    content: '';
    height: 100%;
    width: 100%;
  }

  ${Figure} {
    position: relative;
    margin: 10rem;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2rem 8rem;
  }

  &:first-child {
    &:before {
      opacity: 0.4;
    }

    ${Figure} {
      max-width: 32.5rem;
    }
  }

  &:last-child {
    &:before {
      opacity: 0.6;
    }

    ${Figure} {
      max-width: 41.3rem;
      box-shadow: rgba(0, 0, 0, 0.7) 0px 2rem 8rem;
    }
  }
`

const PromoBox = styled(PromoSection)`
  border-radius: ${dimensions.borderRadius};
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: ${colours.promoBg01};
  min-height: 60vh;

  ${Title} {
    margin-bottom: 0.4em;
  }

  ${Text} {
    line-height: 1.4;
  }

  ${CtaCreateLink} {
    margin-top: 3rem;
  }
`

const PromoBoxAlt = styled(PromoBox)`
  background-color: ${colours.promoBg02};
  color: ${colours.white};
  margin-bottom: 16rem;

  ${Title} {
    display: flex;
    align-items: center;
    column-gap: 2rem;

    ${NewFeatureFlag} {
      margin-top: 1rem;
    }
  }
`

const Quote = styled.blockquote`
  margin: 0;
`

const Cite = styled.cite`
  font-style: normal;
`

const Testimonials = styled(PromoSection)`
  text-align: center;
  margin: 0 auto 16rem;
  position: relative;
  padding: 12rem 10rem 10rem;
  border: 0.2rem solid ${colours.tertiaryLight};
  border-radius: ${dimensions.borderRadius};

  ${Title} {
    font-size: ${fonts.size07};
  }

  ${Text} {
    margin-top: 4rem;
    color: ${colours.grey10};
    font-size: ${fonts.size02};
  }
`

const AboutUs = styled(PromoSection)`
  max-width: 77rem;
  text-align: center;
  margin: 0 auto 0;

  ${Figure} {
    max-width: 46rem;
    margin: auto;
    border-radius: ${dimensions.borderRadius};
    overflow: hidden;
  }

  ${Text} {
    margin-top: 8rem;
    font-size: ${fonts.size04};
  }

  ${Link} {
    color: ${colours.primary};
  }
`

const PartnerFigure = styled(Figure)`
  img {
    border-radius: 0;
  }
`

const PartnerLogosGrid = styled.div`
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 24rem));
  grid-auto-flow: row dense;
  grid-gap: 6rem; */
  display: flex;
  flex-wrap: wrap;
  gap: 6rem;
  align-items: center;
  justify-content: center;
`

const PartnerLogos = styled(PromoSection)`
  text-align: center;
  margin-top: 16rem;
  margin-bottom: 6rem;
  padding-top: 11.5rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    transform: translateX(-50vw);
    width: 100vw;
    height: 0.1rem;
    border-top: 0.1rem solid ${colours.grey04};
  }

  ${Title} {
    margin-bottom: 6rem;
  }

  ${Figure} {
    margin: auto;
  }

  ${Link} {
    /* margin: auto; */
    width: 100%;
    max-width: 14rem;

    &:nth-child(1) img {
      width: 26rem;
      transform: scale(1.15);
    }
    &:nth-child(2) img {
      width: 15.5rem;
    }
    &:nth-child(3) img {
      width: 19rem;
    }
    &:nth-child(4) img {
      width: 15.6rem;
    }
    &:nth-child(5) img {
      width: 15.6rem;
    }
    &:nth-child(7) img {
      transform: scale(1.15);
    }
  }
`

const Carousel = styled((props) => (
  <div className={props.className}>
    <img alt="carousel-01" src={carousel01} />
    <img alt="carousel-02" src={carousel02} />
    <img alt="carousel-03" src={carousel03} />
    <img alt="carousel-04" src={carousel04} />
  </div>
))`
  border-radius: ${dimensions.borderRadius};
  overflow: hidden;
  position: relative;
  height: 0;
  padding-bottom: calc(766 / 1226 * 100%);

  & > img {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    animation: imageCycle 16s infinite; /* Adjust duration as per your requirement */

    &:nth-child(0n + 1) {
      opacity: 1;
      animation: none;
    }
    &:nth-child(0n + 2) {
      animation-delay: 4s;
    }
    &:nth-child(0n + 3) {
      animation-delay: 8s;
    }
    &:nth-child(0n + 4) {
      animation-delay: 12s;
    }
  }
`

const StrapLine = styled(({ cheapestProductPrice, className }) => (
  <section className={className}>
    <div>
      Choose a style, add your Strava/GPX data from an event or adventure and
      frame your achievements — from {cheapestProductPrice}.
    </div>
    <div>
      <CtaCreateLink to={routes.CREATE}>Create Your Map</CtaCreateLink>
    </div>
  </section>
))`
  display: grid;
  grid-template-columns: 4fr 3fr;
  column-gap: 18rem;
  margin: 9rem 0 15rem;
  padding: 0 4rem;
  justify-items: center;
`

const UnstyledHome = (props) => {
  // const handleScrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: 'smooth',
  //   })
  // }

  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <div className={props.className}>
        <HeroSection>
          <FeatureBackground />
          <SiteHeader animateLogo page={routes.HOME} />
          <HeroSiteContainer>
            <FeaturedTitle tag="h1">
              <span>
                Personalised prints of your adventures and events. <br />
                Cycling, running, hiking and more&hellip;
              </span>
            </FeaturedTitle>
            <div className="themes-header">
              <CtaCreateLink to={routes.CREATE}>Get Started</CtaCreateLink>
            </div>
          </HeroSiteContainer>
        </HeroSection>

        {/* <HomeStrava /> */}

        <PromosSiteContainer>
          <PoweredByStravaAlt />

          <Carousel />

          <StrapLine
            cheapestProductPrice={props.cheapestProductPriceFormatted}
          />

          <IconGrid>
            <IconGridInner>
              <Icon icon="printedLocally" />
              <Title tag="h4">Printed Locally</Title>
              <Text>
                Where possible, we use trusted printers closest to you, reducing
                carbon emissions.
              </Text>
            </IconGridInner>
            <IconGridInner>
              <Icon icon="freeShipping" />
              <Title tag="h4">Free Shipping</Title>
              <Text>
                Available worldwide <br />
                (due to local printing!)
              </Text>
            </IconGridInner>
            <IconGridInner>
              <Icon icon="sustainable" />
              <Title tag="h4">Sustainable</Title>
              <Text>
                Water-based inks, sustainably sourced paper, plastic-free &
                vegan… smash!
              </Text>
            </IconGridInner>
          </IconGrid>

          <PromoBoxAlt>
            <PromoBoxInner>
              <Title tag="h3">Gift Vouchers</Title>
              <Text>
                The easiest way to gift a print to loved ones, which they can
                personalise.
                <br />
                <br />
                Get in touch to purchase and receive one to print and wrap.
              </Text>
              <CtaCreateLink to={`mailto:${config.email.contact}`}>
                Email Us
              </CtaCreateLink>
            </PromoBoxInner>
            <PromoBoxImage>
              <Figure image={promo02} />
            </PromoBoxImage>
          </PromoBoxAlt>

          <Testimonials>
            <Quote>
              <Title tag="h5">
                “It’s going to be great to have the Paper Trails map up on my
                wall to remember some of those experiences. After all that's
                what we do all this for. The experience.”
              </Title>
              <Text>
                <Cite>
                  David Bourke
                  <br />
                  Tour Divide Finisher 2019
                </Cite>
              </Text>
            </Quote>
          </Testimonials>

          <AboutUs>
            <Figure image={adamAndJake} />
            <Text>
              Paper Trails is a family run (ha!) business. <br />
              We’re runners, cyclists and only sell products we’d buy
              ourselves.&nbsp;
              <br />
              <Link to={routes.ABOUT}>Learn More</Link>
            </Text>
          </AboutUs>

          <PartnerLogos>
            <Title>Partners and friends</Title>
            <PartnerLogosGrid>
              <Link to="https://gloriousgravel.com/">
                <PartnerFigure image={partnerGG} />
              </Link>
              <Link to="https://www.rhinorun.cc/">
                <PartnerFigure image={partnerRR} />
              </Link>
              <Link to="https://www.pancelticrace.com/">
                <PartnerFigure image={partnerPC} />
              </Link>
              <Link to="https://tribefreedomfoundation.com/">
                <PartnerFigure image={partnerTribe} />
              </Link>
              <Link to="https://13peaks.co.za/">
                <PartnerFigure image={partner13Peaks} />
              </Link>
              <Link to="https://greatbritishdivide.com/">
                <PartnerFigure image={partnerGbDivide} />
              </Link>
              <Link to="https://borderland500.com/">
                <PartnerFigure image={partnerBorderland500} />
              </Link>
              <Link to="https://www.solsticesprint.com/">
                <PartnerFigure image={partnerSolstice} />
              </Link>
            </PartnerLogosGrid>
          </PartnerLogos>
        </PromosSiteContainer>
      </div>
    </React.Fragment>
  )
}

UnstyledHome.propTypes = {
  printId: PropTypes.string,
  printTitle: PropTypes.string,
  defaultActivities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      title: PropTypes.string,
      image: PropTypes.string,
    }),
  ).isRequired,
  themeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      color: PropTypes.string,
    }),
  ).isRequired,
  selectDefaultActivity: PropTypes.func.isRequired,
  cheapestProductPriceFormatted: PropTypes.string,
}
UnstyledHome.defaultProps = {
  printId: undefined,
  printTitle: undefined,
  cheapestProductPriceFormatted: undefined,
}

const Home = styled(UnstyledHome)`
  flex-grow: 1;
  overflow: hidden;
  padding: 0 0 9rem;

  ${PoweredByStravaAlt} {
    width: 24.6rem;
    display: block;
    margin: 0 auto 5rem;
  }

  ${HeroSection} {
    height: 100vh;
    min-height: 90rem;
    position: relative;
    margin-bottom: 0rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  ${PromosSiteContainer} {
    margin-top: 12rem;
  }

  ${SiteHeader} {
    z-index: ${zIndexes.raised};
    ${SiteContainer} {
      padding-bottom: 5rem;
    }
  }

  ${HeroSiteContainer} {
    position: absolute;
    top: -16rem;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ${FeaturedTitle} {
    text-align: center;
    margin: 0 auto;
    max-width: 24em;
    line-height: ${fonts.lineHeight02};
    color: ${colours.white};

    ${SmallText} {
      font-size: ${fonts.size02};
      margin-top: 3rem;
    }
  }

  .themes-header {
    position: absolute;
    bottom: 40rem;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;

    ${CtaCreateLink} {
      &:after {
        content: '';
        width: 3px;
        height: 42rem;
        top: calc(100% + 6rem);
        position: absolute;
        ${dashedLine}
      }
    }

    ${Emoji} {
      display: block;
      margin-top: 2rem;
      font-size: 52px;
    }
  }

  .organisers {
    padding-top: 20rem;
    padding-bottom: 32rem;
    text-align: center;
    position: relative;

    ${TopoBackground} {
      top: -80rem;
      background-position: center center;
    }

    ${Title} {
      margin-left: auto;
      margin-right: auto;
      max-width: 32em;
    }
    ${Link} {
      text-decoration: underline;
      color: ${colours.primary};

      &:hover {
        color: ${colours.secondary};
        text-decoration: none;
      }
    }
  }

  /* MEDIA STYLES */

  @media screen and (min-width: ${mediaSizes.nav}px) and (max-height: 1100px) {
    .themes-header {
      bottom: auto;
      top: 72%;

      ${SectionTitle} {
        &:after {
          height: calc(10vw + 45.3rem);
          min-height: 0;
        }
      }
    }
  }

  ${media.footer`

    ${IconGridInner} {
      padding: 2rem;
    }

    ${PrintGridA} {
      grid-template-columns: 5.5fr 7fr;

      ${PoweredByStravaAlt} {
        margin-left: 12rem;
      }
    }

    ${PrintGridB} {
      grid-template-columns: 5fr 4fr;
      grid-gap: 6rem;

      .print-04 {
        max-width: 24rem;
      }
    }

    ${PromoBoxInner} {
      padding: 4rem;
    }

    ${PromoBoxImage} {
      ${Figure} {
        margin:4rem;
      }
    }

    ${Testimonials} {
      padding: 10rem 5rem 5rem;
    }

    ${PartnerLogosGrid} {
      grid-gap: 4rem;
    }

    ${HeroSection} {
      min-height: 80rem;
    }

    ${FeaturedTitle} {

      br { display: none; }
    }

    .organisers {
      padding-top: 12rem;
      padding-bottom: 12rem;

      ${TopoBackground} {
        top: -50rem;
      }
    }
    .themes-header {
      ${Emoji} {
        margin-top: 0.4em;
        font-size: 38px;
      }
      ${CtaCreateLink} {
        &:after {
          height: 32vh;
        }
      }

    }
    ${ThemesListItem} {
      &.portrait {
        &:nth-child(4n+1) ${ThemesListItemInner} {
          padding-left: 0;
        }
        &:nth-child(4n+3) ${ThemesListItemInner} {
          padding-right: 0;
        }
      }

      &:nth-child(0n+1) { order: 1; }
      &:nth-child(0n+2) { order: 2; }

      &:nth-child(0n+3) { order: 4; }
      &:nth-child(0n+4) { order: 3; }

      &:nth-child(0n+5) { order: 5; }
      &:nth-child(0n+6) { order: 6; }

      &:nth-child(0n+7) { order: 8; }
      &:nth-child(0n+8) { order: 7; }

      &:nth-child(0n+9) { order: 9; }
      &:nth-child(0n+10) { order: 10; }

      &:nth-child(0n+11) { order: 12; }
      &:nth-child(0n+12) { order: 11; }
    }
  `}

  ${media.nav`
    ${NewFeatureFlag} {
      font-size: ${fonts.size01};
    }

    ${PromosSiteContainer} {
      margin-top: 2rem;
      ${Title} {
        font-size: ${fonts.size03};
      }
    }

    ${CtaCreateLink} {
      font-size: ${fonts.size02};
    }

    ${PrintGridA} {
      grid-template-columns: 1fr;
      grid-gap: 4rem;

      ${Title} {
        margin-top: 4rem;
        justify-content: center;
        column-gap: 1.5rem;
      }

      ${CtaCreateLink} {
        margin-top: 2rem;
      }

      ${PoweredByStravaAlt} {
        display: none;
      }
    }

    ${PoweredByStravaAlt} {
      width: 19.2rem;
    }

    ${PrintGridB} {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-gap: 4rem;
      text-align: center;

      ${Title} {
        margin-top: 0;
      }

      .print-03 {
        margin: auto;
        max-width: 50rem;
      }

      .print-04 {
        display: none;
      }
    }

    ${DashedLine},
    ${PromoSection} {
      margin-top: 6rem;
      margin-bottom: 6rem;
    }

    ${PromoBoxInner} {
      padding: 4rem;
    }

    ${PromoBoxImage} {
      ${Figure} {
        margin: 6rem 4rem;
      }
    }

    ${PromoBox} {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;

      ${CtaCreateLink} {
        margin-top: 2rem;
      }
    }

    ${PromoBoxAlt} {
      ${Title} {
        column-gap: 1.5rem;
      }
      ${NewFeatureFlag} {
        margin-top: 0;
      }
    }

    ${Testimonials} {
      padding: 4rem 2rem;

      ${Title} {
        font-size: ${fonts.size02};
      }

      ${Text} {
        margin-top: 1em;
        font-size: ${fonts.size06};
      }
    }

    ${AboutUs} {
      ${Text} {
        font-size: ${fonts.size02};
      }
      br { display: none; }
    }

    ${DashedLine} {
      height: 15rem;
    }

    ${ToTheTop} {
      ${DashedLine} {
        margin-bottom: 0;
      }
    }

    ${PartnerLogos} {
      padding-top: 6rem;
      margin-top: 12rem;

      ${Title} {
        margin-bottom: 4rem;
      }
    }

    ${PartnerLogosGrid} {
      ${Figure} {
        &:nth-child(0n+1) img { width: 100%; }
      }
    }

    ${IconGrid} {
      grid-gap: 2rem;
      grid-template-columns: 1fr;
      grid-template-rows: auto

      ${Title} {
        font-size: ${fonts.size02};
      }

      ${Icon} {
        width: 8rem;
      }

      .isvg {
        margin-bottom: 2rem;
      }

      br {
        display: none;
      }
    }

    ${IconGridInner} {
      grid-template-rows: auto auto auto;
    }

    ${ElevationProfileSvg} {
      height: 9rem;
    }

    ${HeroSection} {
      min-height: 70rem;
    }

    ${StrapLine} {
      margin-top: 4rem;
      margin-bottom: 8rem;
      grid-template-columns: auto;
      row-gap: 4rem;
      padding-left: 0;
      padding-right: 0;
    }

    ${ThemeCardTitle} {
      display: none;
    }

    ${ThemeCard} {
      margin-bottom: 0;
    }

    ${ThemesListItemInner} {
      align-items: center;
    }

    ${HeroSiteContainer} {
      top: -8rem;
    }

    .themes-header {
      /* bottom: 20rem; */
      position: relative;
      bottom: auto;
      top: auto;
      margin: 4rem 0 0;

      ${SectionTitle} {
        &:after {
          height: 20rem;
        }
      }
    }

    .organisers {
      ${Title} {
        font-size: ${fonts.size02};
      }
    }

    .organisers {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }

  `}
`

const withActivities = WithActivities()
const withSession = WithSession()
const withPrint = WithPrint()
const withThemes = WithThemes()

export { Home, UnstyledHome }
export default compose(withActivities, withPrint, withThemes, withSession)(Home)
